import React from "react"
import "../components/connectForm.scss"

export default class ConnectForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }

  render() {
    const { status } = this.state
    return (
      <form
        onSubmit={this.submitForm}
        action="https://formspree.io/f/mayawbkp"
        method="POST"
        className="connect-form"
      >
        {status !== "SUCCESS" && (
          <>
            <h1 className="connect-form__title">How can I help?</h1>
            {/* <label>Name:</label> */}{" "}
            <input
              type="text"
              name="name"
              className="connect-form__textbox"
              placeholder="Name"
            />
            <input
              type="email"
              name="email"
              className="connect-form__textbox"
              placeholder="Email"
            />
            <textarea
              name="message"
              className="connect-form__textbox connect-form__textbox--2"
              placeholder="Message"
            />
          </>
        )}
        {status === "SUCCESS" ? (
          <>
            <p className="connect-form__success-msg">
              SUCCESS! <br />
              I'll get back to you as soon as I can.
            </p>
          </>
        ) : (
          <button className="connect-form__button">Submit</button>
        )}
        {status === "ERROR" && (
          <p className="connect-form__error-msg">
            Ooops! There was an error. Refresh the page and try again.
          </p>
        )}
      </form>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}
