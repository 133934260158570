import React from "react"
import Layout from "../components/layout.js"
import ConnectForm from "../components/connectForm.js"
import "./contact.scss"
import GatsbyImageSharpFluid from "../components/gatsbyImageSharpFluid.js"
import Head from "../components/head.js"

const Contact = () => {
  return (
    <>
      <Layout>
        <Head title={"Contact"} />
        <div className="contact">
          <ConnectForm />
          <GatsbyImageSharpFluid
            imgsrc={"contact.jpg"}
            className={"contact__picture"}
          ></GatsbyImageSharpFluid>
        </div>
      </Layout>
    </>
  )
}
export default Contact
